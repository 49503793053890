header {
  position: relative;
}

#home {
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  #home {
    height: 100vh;
  }
}

#home .home-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.home-content h1 {
  text-transform: uppercase;
}
.home-content button {
  margin-top: 20px;
}

.header-wrapper h2 {
  display: inline-block;
  margin-bottom: 0px;
}

.header-wrapper .breadcrumb {
  float: right;
  background: transparent;
  margin-bottom: 0px;
}

.header-wrapper .breadcrumb .breadcrumb-item.active {
  color: #868F9B;
}

.breadcrumb>li+li:before {
  color: #868F9B;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10,10,10,.6);
}

@media screen and (min-width: 992px) {
  .header-lead-p {
    font-size: 2.3em;
    margin-bottom: 2rem;
  }
}