.pd0{
  margin: 0;
  padding: 0;
}

#prices span{
  font-size:30px;
  color:#FFF;
}

#prices h3{
  color:#FFF;
}

#prices .features li{
  padding:10px;
}

#prices .btn.btn-default{
  margin:20px 0px;
}

#prices .features-border li{
  border-top:1px solid #c7c7c7;
  padding: 10px 0px;
}

#prices .features-border li:last-child{
  border-bottom:1px solid #c7c7c7;
}

#price2 {
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  z-index: 2;
}

#price2:before {
  content: "";
  width: 100%;
  height: 40px;
  background: #4d4d4d;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  margin: 0 auto;
}

.btn.btn-default {
  background-color: #5db4c0;
  color: #fff;
  border-radius: 0;
  border: none;
  padding: 13px 20px;
  font-size: 13px;
  font-weight: 600
}

.prices-box{
  margin:50px 0px;
}

.top-content{
  background-color:#4d4d4d;
  padding:20px;
}

.bottom-content{
  background-color:#f6f6f6;
  padding:20px 20px;
}

.big-price span{
  color: #333;
  font-size:40px;
}

.btn-price{
  background-color:#5db4c0;
  color: #fff;
  border-radius:50%;
  border:none;
  padding:40px 20px;
  font-size: 13px;
  font-weight: 600;
  margin-top:20px;
}

.price-title {
  h3 {
    color: $main-white;
  }

  h4 {
    color: $main-white;
  }
}

.main-accent {
  color: $main-blue;
}

.price-table {
  th {
    font-size: 0.85em;
  }

  .prices-container {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .price-big {
    font-size: 2.2em;
  }
}