@media screen and (min-width: 992px) {
  .section-rodo {
    //min-height: 100vh;

    h2 {
      &.title {
        margin-top: 20px;
      }
    }
  }

  .rodo-content-wrapper {
    padding-top: 100px;
  }
}