h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
  color: $main-dark;
}

h1 {
  font-size: 54px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

a {
  color: $main-blue;
  text-decoration: none;
  transition: 0.2s opacity;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  opacity: 0.8;
  color: $main-blue;
}

.main-color {
  color: $main-blue;
}

.white-text {
  color: $main-white;
}

::-moz-selection {
  background-color: $main-blue;
  color: $main-white;
}

::selection {
  background-color: $main-blue;
  color: $main-white;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none
}