footer {
  padding-top: 50px;
  padding-bottom: 20px;
}

footer .footer-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer {
  background: $third-dark !important;
}
#footer h5{
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 20px;
  color:$main-white;
  font-weight: 500;
}
#footer a {
  color: $main-white;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-weight: 500;
}
#footer ul.social li{
  padding: 3px 0;
}
#footer ul.social li a i {
  margin-right: 5px;
  font-size:25px;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}
#footer ul.social li:hover a i {
  font-size:30px;
  margin-top:-10px;
}
#footer ul.social li a,
#footer ul.quick-links li a{
  color:$main-white;
}
#footer ul.social li a:hover{
  color:#eeeeee;
}
#footer ul.quick-links li{
  padding: 3px 0;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}
#footer ul.quick-links li:hover{
  padding: 3px 0;
  margin-left:5px;
  font-weight:500;
}
#footer ul.quick-links li a i{
  margin-right: 5px;
}
#footer ul.quick-links li:hover a i {
  font-weight: 500;
}

@media (max-width:767px){
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}