video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
   //background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat;
  background-size: cover;
  transition: 1s opacity;

  &.max-height-100 {
    max-height: 100%;
  }
}