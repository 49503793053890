body {
  font-family: 'Varela Round', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
  color: #868F9B;
}

.section {
  position: relative;
}

.md-padding {
  padding-top: 60px;
  padding-bottom: 30px;
}

.sm-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 992px) {
  .md-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sm-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.text-accent {
  color: $main-blue;
}

.section-header {
  position: relative;
  margin-bottom: 60px;
}

.section-header .title {
  text-transform: uppercase;

  @media screen and (min-width: 992px) {
    &.title-white {
      color: $main-white;
    }
  }
}

.title:after {
  content:"";
  display:block;
  height:4px;
  width:80%;
  background-color: $main-blue;
  margin-top: 20px;
}

.text-center .title:after {
  margin: 20px auto 0px;
}

.bg-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bg-img .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .8;
  background: #1C1D21;
}

h2 {
  &.title {
    margin-top: 20px;
  }
}
