.pricing-slider-wrapper {
  .price-box {
    margin: 0 auto;
    //background: #E9E9E9;
    border-radius: 10px;
    padding: 1px 15px;
  }

  @media screen and (min-width: 992px) {
    .price-box {
      width: 500px;
    }
  }

  .ui-widget-content {
    border: 1px solid #bdc3c7;
    background: #e1e1e1;
    color: #222222;
    margin-top: 4px;
  }

  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 5.2em;
    height: 2.2em;
    cursor: default;
    margin: 0 -40px auto !important;
    text-align: center;
    line-height: 30px;
    color: #FFFFFF;
    font-size: 15px;
  }

  .ui-slider .ui-slider-handle .glyphicon {
    color: #FFFFFF;
    font-size: 13px;
    opacity: 0.5;
  }

  .ui-corner-all {
    border-radius: 20px;
  }

  .ui-slider-horizontal .ui-slider-handle {
    top: -.9em;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default {
    border: 1px solid #f9f9f9;
    background: $main-blue;
  }

  .ui-slider-horizontal .ui-slider-handle {
    margin-left: -0.5em;
  }

  .ui-slider .ui-slider-handle {
    cursor: pointer;
  }

  .ui-slider span,
  .ui-slider span:focus {
    cursor: pointer;
    outline: none;
  }

  .price, .lead p {
    font-weight: 600;
    font-size: 32px;
    display: inline-block;
    line-height: 60px;
  }

  h4.great {
    background: $main-blue;
    margin: 0 0 25px 0px;
    padding: 7px 15px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    display: inline-block;
    -moz-box-shadow:    2px 4px 5px 0 #ccc;
    -webkit-box-shadow: 2px 4px 5px 0 #ccc;
    box-shadow:         2px 4px 5px 0 #ccc;
  }

  @media all and (min-width: 992px) {
    h4.great {
      margin: 0 0 25px -60px;
    }
  }

  .total {
    //border-bottom: 1px solid #7f8c8d;
    /*display: inline;
    padding: 10px 5px;*/
    position: relative;
    padding-bottom: 0px;
  }

  //.total:before {
  //  content: "";
  //  display: inline;
  //  position: absolute;
  //  left: 0;
  //  bottom: 5px;
  //  width: 100%;
  //  height: 3px;
  //  background: #7f8c8d;
  //  opacity: 0.5;
  //}

  .price-slider {
    margin-bottom: 25px;
  }

  .price-slider span {
    font-weight: 200;
    display: inline-block;
    color: #7f8c8d;
    font-size: 25px;
    margin-left: 15px;
  }

  .form-pricing {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
  }

  .price-form {
    background: #ffffff;
    margin-bottom: 0px;
    padding: 0px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    /*-moz-box-shadow:    0 5px 5px 0 #ccc;
      -webkit-box-shadow: 0 5px 5px 0 #ccc;
      box-shadow:         0 5px 5px 0 #ccc;*/
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-group span.price {
    font-weight: 200;
    display: inline-block;
    color: #7f8c8d;
    font-size: 14px;
  }

  .help-text {
    display: block;
    margin-top: 32px;
    margin-bottom: 10px;
    color: #737373;
    position: absolute;
    /*margin-left: 20px;*/
    font-weight: 200;
    text-align: right;
    width: 188px;
  }

  .price-form label {
    font-weight: 200;
    font-size: 21px;
  }

  img.payment {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .ui-slider-range-min {
    background: darken($main-blue, 10);
  }

  /* HR */

  hr.style {
    margin-top: 0;
    border: 0;
    border-bottom: 1px dashed #ccc;
    background: #999;
  }
}
